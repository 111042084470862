import React from "react";
import { DateRangePicker } from "react-date-range";
import Modal from "react-modal";
import translationDE from "../../translations/manageReturns/translationDE.json";
import translationEN from "../../translations/manageReturns/translationEN.json";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
Modal.setAppElement("#root");

class CustomerReturnsExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
    this.t = this.props.lang == "en" ? translationEN : translationDE;
  }

  getInitialState = () => ({
    exportAllReturns: true,
    exportByDate: false,
    exportByArrivedDate: false,
    exportByRestockedDate: false,
    exportCondition: "returnAll",
    exportStartDate: new Date(),
    exportEndDate: new Date(),
    exportSuccess: false,
    maxModalHeight: false,
    showDatePicker: false,
  });

  closeExportModal = () => {
    this.props.closeExportModal();
    this.state = this.getInitialState();
  };

  handleInputChange = (event) => {
    const name = event.target.name;

    this.setState({
      ...this.getInitialState(),
      exportCondition: name,
      exportAllReturns: false,
      exportByDate: false,
      exportByArrivedDate: false,
      exportByRestockedDate: false,
    });

    if (name === "returnAll") {
      this.setState({
        exportAllReturns: true,
      });
    } else if (name === "returnByDate") {
      this.setState({
        exportByDate: true,
        maxModalHeight: true,
        showDatePicker: true,
      });
    } else if (name === "returnByArrivedDate") {
      this.setState({
        exportByArrivedDate: true,
        maxModalHeight: true,
        showDatePicker: true,
      });
    } else if (name === "returnByRestockedDate") {
      this.setState({
        exportByRestockedDate: true,
        maxModalHeight: true,
        showDatePicker: true,
      });
    }
  };

  handleSelectDate = (ranges) => {
    this.setState({
      exportStartDate: ranges.selection.startDate,
      exportEndDate: ranges.selection.endDate,
    });
  };

  exportCustomerReturns = () => {
    fetch(
      `/customer_returns/export?value=${this.state.exportCondition}&start_date=${this.state.exportStartDate}&end_date=${this.state.exportEndDate}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    ).then((response) => {
      if (response.status === 200) {
        this.setState({ exportSuccess: true });
      }
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.showExportModal}
        onRequestClose={this.closeExportModal}
        style={{
          content: {
            maxWidth: "700px",
            maxHeight: this.state.maxModalHeight ? "700px" : "370px",
            margin: "auto",
            padding: "0",
          },
        }}
      >
        <div className="border-b-2 p-5 w-full border-gray-250 pb-4 text-gray-950 font-dm_sans">
          <button
            className=" bg-transparent text-black right-10 absolute no-underline text-base font-extrabold cursor-pointer"
            onClick={this.closeExportModal}
          >
            x
          </button>
          <p className="text-gray-950 font-medium block mt-1 tracking-px_4 font-dm_sans">
            {this.t.export_returns}
          </p>
        </div>
        {this.state.exportSuccess && (
          <div className="p-5">
            <h3 className="tracking-px_3 font-medium mt-2  text-gray-950">
              {this.t.success_heading}
            </h3>
            <p className="text-sm mt-2 text-gray-950 font-dm_sans tracking-px_4">
              {" "}
              {this.t.export_subheading1} {this.props.userEmail}.{" "}
              {this.t.export_subheading2}
            </p>
          </div>
        )}
        {!this.state.exportSuccess && (
          <div className="p-5">
            <label className="block mt-5 text-gray-950 font-dm_sans tracking-px_4">
              <input
                name="returnAll"
                type="radio"
                checked={this.state.exportAllReturns}
                onChange={this.handleInputChange}
                className="mr-2"
              />
              {this.t.all_returns}
            </label>
            <label className="block mt-2 text-gray-950 font-dm_sans tracking-px_4">
              <input
                name="returnByDate"
                type="radio"
                checked={this.state.exportByDate}
                onChange={this.handleInputChange}
                className="mr-2 "
              />
              {this.t.returns_by_date}
            </label>
            <label className="block mt-2 text-gray-950 font-dm_sans tracking-px_4">
              <input
                name="returnByArrivedDate"
                type="radio"
                checked={this.state.exportByArrivedDate}
                onChange={this.handleInputChange}
                className="mr-2 "
              />
              {this.t.returns_by_arrived_date}
            </label>
            {this.props.scayleIntegration && (
              <label className="block mt-2 text-gray-950 font-dm_sans tracking-px_4">
                <input
                  name="returnByRestockedDate"
                  type="radio"
                  checked={this.state.exportByRestockedDate}
                  onChange={this.handleInputChange}
                  className="mr-2 "
                />
                {this.t.returns_by_restocked_date}
              </label>
            )}
            <div className="block mt-2 text-gray-950 font-dm_sans tracking-px_4">
              {this.state.showDatePicker && (
                <DateRangePicker
                  ranges={[
                    {
                      startDate: this.state.exportStartDate,
                      endDate: this.state.exportEndDate,
                      key: "selection",
                    },
                  ]}
                  onChange={this.handleSelectDate}
                  editableDateInputs={true}
                  moveRangeOnFirstSelection={false}
                  direction="vertical"
                  scroll={{ enabled: true }}
                />
              )}
            </div>
          </div>
        )}

        {!this.state.exportSuccess && (
          <div className="border-t-2 mr-5  border-gray-250 mt-6 flex justify-end font-dm_sans tracking-px_4">
            <button
              className="mb-5 pl-10 pr-10  bg-green-950 text-white  rounded mt-5 cursor-pointer text-center tracking-px_4 p-3"
              onClick={this.exportCustomerReturns}
            >
              {this.t.export_modal_btn}
            </button>
          </div>
        )}
      </Modal>
    );
  }
}

export default CustomerReturnsExport;
